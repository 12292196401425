import { createRoot } from 'react-dom/client';
import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import App from './App.jsx';

const container = document.getElementById('react');
const root = createRoot(container);

const theme = createTheme({
  palette: {
    text: {
      primary: '#3A3A3A',
      secondary: '#3A3A3A',
    },
    primary: {
      main: '#8BC34A',
      contrastText: '#fff',
    },
    secondary: {
      main: '#050505', // #acd96f
      contrastText: '#fff',
    },
    custom: {
      active: '#d5d5d5',
      panel: '#E8E8E8',
    },
  },
  typography: {
    fontFamily: '"Exo", sans-serif',
    color: '#3A3A3A',
    body1: {
      fontSize: '0.75rem',
    },
  },
  components: {
    MuiTextareaAutosize: {
      styleOverrides: {
        root: {
          fontFamily: '"Exo", sans-serif',
          '&:focus': {
            borderColor: '#8BC34A',
            outline: 0,
            boxShadow: '#8BC34A',
          },
          '&:hover': {
            borderColor: '#8BC34A',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Exo", sans-serif',
          color: '#3A3A3A',
        },
      },
      body1: {
        fontSize: '0.75rem',
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          color: '#3A3A3A',
        },
        contained: {
          backgroundColor: '#8BC34A',
          '&:hover': {
            backgroundColor: '#7DAF3C',
          },
        },
      },
      variants: [
        {
          props: { variant: 'callToAction' },
          style: {
            backgroundColor: '#EC6969',
            color: 'white',
            width: 'fit-content',
            '&:hover': {
              backgroundColor: '#eb4a4a',
            },
          },
        },
      ],
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          color: '#3A3A3A',
          '& label.Mui-focused': {
            color: '#3A3A3A',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: '#8BC34A',
        },
        track: {
          color: '#7DAF3C',
        },
        rail: {
          color: '#9CCF56',
        },
      },
    },
  },
});

root.render(
  <ThemeProvider theme={theme}>
    <ToastContainer />
    <App />
  </ThemeProvider>,
);
