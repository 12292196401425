import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import axios from 'axios';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import AuthenticationContext from '../context/AuthenticationContext.jsx';
import { auth } from '../../firebase.config.js';

const AuthenticationProvider = ({ children }) => {
  const api = axios.create({
    baseURL: '/',
  });
  const [activeLogin, setActiveLogin] = useState(true);
  const [user, setUser] = useState({});

  const signOutUser = async () => {
    await signOut(auth);
  };

  let isTokenRefreshing = false;
  let subscribers = [];

  const subscribeTokenRefresh = (cb) => {
    subscribers.push(cb);
  };

  const onTokenRefreshed = (newToken) => {
    subscribers.forEach((cb) => cb(newToken));
    subscribers = [];
  };

  const refreshAuthToken = async () => {
    try {
      const tokenRes = await auth.currentUser.getIdTokenResult(true);
      const { token } = tokenRes;
      const { role } = tokenRes.claims;
      sessionStorage.setItem('token', JSON.stringify({ token }));
      setUser((prevState) => ({ ...prevState, role, token }));
      isTokenRefreshing = false;
      onTokenRefreshed(token);
      return token;
    } catch (error) {
      isTokenRefreshing = false;
      throw error;
    }
  };

  api.interceptors.request.use(
    async (config) => {
      if (config._bypassInterceptor) {
        return config;
      }
      if (user.token) {
        // eslint-disable-next-line
        config.headers.Authorization = `Bearer ${user.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (!isTokenRefreshing && auth.currentUser) {
          isTokenRefreshing = true;
          originalRequest._retry = true;
          try {
            const newToken = await refreshAuthToken();
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            originalRequest._bypassInterceptor = true;
            return api(originalRequest);
          } catch (refreshError) {
            return Promise.reject(refreshError);
          }
        } else {
          return new Promise((resolve) => {
            subscribeTokenRefresh((newToken) => {
              originalRequest.headers.Authorization = `Bearer ${newToken}`;
              originalRequest._bypassInterceptor = true;
              resolve(api(originalRequest));
            });
          });
        }
      }
      return Promise.reject(error);
    },
  );

  useEffect(() => {
    const storedUser = sessionStorage.getItem('token');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    onAuthStateChanged(auth, (userStateChanged) => {
      if (userStateChanged) {
        auth.currentUser.getIdTokenResult(true).then((tokenRes) => {
          const { email } = userStateChanged;
          const { token } = tokenRes;
          const { role } = tokenRes.claims;
          sessionStorage.setItem('token', JSON.stringify({ token }));
          const name = userStateChanged.displayName;
          setUser({
            email, name, token, role,
          });
          setActiveLogin(false);
        })
          .catch(() => {
            toast.error('Encountered an error logging you in!');
            setUser({});
            setActiveLogin(true);
          });
      } else {
        sessionStorage.removeItem('token');
        setUser({});
        setActiveLogin(false);
      }
    });
  // eslint-disable-next-line
  }, []);

  const value = useMemo(() => ({
    user,
    setUser,
    api,
    activeLogin,
    authStatus: !!user?.token,
    signOutUser,
  // eslint-disable-next-line
  }), [user, activeLogin, api]);

  return (
    <AuthenticationContext.Provider value={value}>
      {children}
    </AuthenticationContext.Provider>
  );
};

AuthenticationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticationProvider;
