import React, {
  Suspense,
  lazy,
} from 'react';
import {
  CircularProgress,
} from '@mui/material';
import {
  Routes,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import AuthenticationProvider from './providers/AuthenticationProvider.jsx';
import PlantSelectorProvider from './providers/PlantSelectorProvider.jsx';

const PrivateRoute = lazy(() => import('./components/PrivateRoute.jsx'));
const Authentication = lazy(() => import('./pages/Authentication.jsx'));
const HyphaeHome = lazy(() => import('./pages/HyphaeHome.jsx'));
const ProjectDashboard = lazy(() => import('./pages/ProjectDashboard.jsx'));
const BuildPlantingDesign = lazy(() => import('./pages/BuildPlantingDesign.jsx'));
const ViewProjectMap = lazy(() => import('./pages/ViewProjectMap.jsx'));
const QueryRedesign = lazy(() => import('./pages/QueryRedesign.jsx'));
const InitializeQuery = lazy(() => import('./pages/InitializeQuery.jsx'));
const LookUpPlant = lazy(() => import('./pages/LookUpPlant.jsx'));
const ProjectPlantList = lazy(() => import('./pages/ProjectPlantList.jsx'));
const InventoryPlantList = lazy(() => import('./pages/InventoryPlantList.jsx'));
const UploadData = lazy(() => import('./pages/UploadData.jsx'));
const PackedPlantingDesign = lazy(() => import('./pages/PackedPlantingDesign.jsx'));
const NotFound = lazy(() => import('./pages/NotFound.jsx'));
const Schools = lazy(() => import('./pages/kits/Schools.jsx'));
const SchoolsOverview = lazy(() => import('./pages/kits/SchoolsOverview.jsx'));
const Landing = lazy(() => import('./pages/Landing.jsx'));
const Info = lazy(() => import('./pages/Info.jsx'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Project3DMap = lazy(() => import('./pages/Project3DMap.jsx'));

const WestOaklandPage = lazy(() => import('./pages/kits/WestOakland.jsx'));
const SouthLouisvillePage = lazy(() => import('./pages/kits/SouthLouisville.jsx'));
const GeoMvpPage = lazy(() => import('./pages/GeoMvp.jsx'));


const PlantSelectorProjectRoutes = () => (
  <PlantSelectorProvider>
    <Routes>
      <Route path="build-planting-design" element={<BuildPlantingDesign />} />
      <Route path="view-project-map" element={<ViewProjectMap />} />
      <Route path="query" element={<QueryRedesign />} />
      <Route path="initialize-query" element={<InitializeQuery />} />
      <Route path="look-up-plant" element={<LookUpPlant />} />
      <Route path="project-inventory-list" element={<InventoryPlantList />} />
      <Route path="project-plant-list" element={<ProjectPlantList />} />
      <Route path="project-dashboard" element={<ProjectDashboard />} />
      <Route path="upload-data" element={<UploadData />} />
      <Route path="packed-planting-design" element={<PackedPlantingDesign />} />
      <Route path="project-3d-map" element={<Project3DMap />} />
      <Route path="not-found" element={<NotFound />} />
      <Route path="/" element={<ProjectDashboard />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </PlantSelectorProvider>
);
const App = () => (
  <Router>

    <AuthenticationProvider>
      <Suspense
        fallback={(
          <div style={{ display: 'flex', p: 2 }}>
            <CircularProgress />
          </div>
        )}
      >
        <Routes>
          <Route element={<PrivateRoute acceptedLevels={['hyphae']} />}>
            <Route path="/geomvp/:mapId" element={<GeoMvpPage />} />
            <Route path="/geomvp" element={<GeoMvpPage />} />
            <Route path="/app-home" element={<HyphaeHome />} />
            <Route path="/:id/*" element={<PlantSelectorProjectRoutes />} />
          </Route>
          <Route element={<PrivateRoute acceptedLevels={['hyphae', 'public']} />}>
            <Route path="/kits/schools" element={<Schools />} />
          </Route>
          <Route path="/kits/schools/overview" element={<SchoolsOverview />} />
          {/* <Route path="/kits/west-oakland" element={<WestOaklandPage />} />
          <Route path="/kits/south-louisville" element={<SouthLouisvillePage />} /> */}
          <Route path="/info" element={<Info />} />
          <Route path="/login" element={<Authentication />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="not-found" element={<NotFound />} />
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>

    </AuthenticationProvider>
  </Router>
);

export default App;
